import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';

import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { Facebook } from '@ionic-native/facebook/ngx';
import { Camera } from '@ionic-native/camera/ngx';
import { File } from '@ionic-native/file/ngx';
import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import { HttpClientModule } from '@angular/common/http';
import { RequestModalPageModule } from './request-modal/request-modal.module';
import { ReviewModalPageModule } from './review-modal/review-modal.module';
import { DatemeModalPageModule } from './dateme-modal/dateme-modal.module';
import { HonorModalPageModule } from './honor-modal/honor-modal.module';
import { ShowReviewModalPageModule } from './show-review-modal/show-review-modal.module';
import { PartyPopoverComponent } from './party-popover/party-popover.component';
import { ProfilePopoverComponent } from './profile-popover/profile-popover.component';
import { EmailComposer } from '@ionic-native/email-composer/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { Badge } from '@ionic-native/badge/ngx';

@NgModule({
  declarations: [AppComponent, PartyPopoverComponent, ProfilePopoverComponent],
  entryComponents: [PartyPopoverComponent, ProfilePopoverComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features,
    AngularFireStorageModule, // imports firebase/storage only needed for storage features
    HttpClientModule,
    RequestModalPageModule,
    ReviewModalPageModule,
    DatemeModalPageModule,
    HonorModalPageModule,
    ShowReviewModalPageModule
  ],
  providers: [
    SplashScreen,
    GooglePlus,
    Facebook,
    Camera,
    File,
    FirebaseX,
    EmailComposer,
    Geolocation,
    Badge,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
