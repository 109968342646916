import { Component, OnInit, Input, Renderer2 } from '@angular/core';
import { UserService } from '../services/user.service';
import { PartyService } from '../services/party.service';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-honor-modal',
  templateUrl: './honor-modal.page.html',
  styleUrls: ['./honor-modal.page.scss'],
})
export class HonorModalPage implements OnInit {
  @Input() hostID: string;
  @Input() hostName: string;
  @Input() image: string;
  @Input() guestName: string;
  @Input() hostImage: string;
  @Input() honored: string;
  @Input() internal_id: string;
  @Input() reviewerID: string;
  @Input() reviewerName: number;
  @Input() reviewerImage: number;
  @Input() partyLevel: number;
  @Input() hostBadges: any;

  counter = 0;

  badges = [];

  profileBadges = {
    Ambiance: 0,
    Drinks: 0,
    Entertaining: 0,
    Flirty: 0,
    Food: 0,
    Music: 0,
    Organized: 0,
    Social: 0,
  };

  constructor(
    private userService: UserService,
    private partyService: PartyService,
    private renderer: Renderer2,
    private modalController: ModalController
  ) {}

  ngOnInit() {
    this.profileBadges = this.hostBadges;
  }

  selectBadges(badge) {
    if (this.counter <= 3) {
      switch (badge) {
        case 'Ambiance':
          if (this.badges.includes('Ambiance')) {
            this.badges.slice(this.badges.indexOf('Ambiance'), 1);
            this.counter = this.counter - 1;
          } else {
            if (this.counter === 3) {
              window.alert('You can only award three badges!');
            } else {
              this.counter = this.counter + 1;
              this.badges.push('Ambiance');
            }
          }

          break;

        case 'Drinks':
          if (this.badges.includes('Drinks')) {
            this.badges.slice(this.badges.indexOf('Drinks'), 1);
            this.counter = this.counter - 1;
          } else {
            if (this.counter === 3) {
              window.alert('You can only award three badges!');
            } else {
              this.counter = this.counter + 1;
              this.badges.push('Drinks');
            }
          }

          break;

        case 'Entertaining':
          if (this.badges.includes('Entertaining')) {
            this.badges.slice(this.badges.indexOf('Entertaining'), 1);
            this.counter = this.counter - 1;
          } else {
            if (this.counter === 3) {
              window.alert('You can only award three badges!');
            } else {
              this.counter = this.counter + 1;
              this.badges.push('Entertaining');
            }
          }

          break;

        case 'Flirty':
          if (this.badges.includes('Flirty')) {
            this.badges.slice(this.badges.indexOf('Flirty'), 1);
            this.counter = this.counter - 1;
          } else {
            if (this.counter === 3) {
              window.alert('You can only award three badges!');
            } else {
              this.counter = this.counter + 1;
              this.badges.push('Flirty');
            }
          }

          break;

        case 'Food':
          if (this.badges.includes('Food')) {
            this.badges.slice(this.badges.indexOf('Food'), 1);
            this.counter = this.counter - 1;
          } else {
            if (this.counter === 3) {
              window.alert('You can only award three badges!');
            } else {
              this.counter = this.counter + 1;
              this.badges.push('Food');
            }
          }

          break;

        case 'Music':
          if (this.badges.includes('Music')) {
            this.badges.slice(this.badges.indexOf('Music'), 1);
            this.counter = this.counter - 1;
          } else {
            if (this.counter === 3) {
              window.alert('You can only award three badges!');
            } else {
              this.counter = this.counter + 1;
              this.badges.push('Music');
            }
          }

          break;

        case 'Organized':
          if (this.badges.includes('Organized')) {
            this.badges.slice(this.badges.indexOf('Organized'), 1);
            this.counter = this.counter - 1;
          } else {
            if (this.counter === 3) {
              window.alert('You can only award three badges!');
            } else {
              this.counter = this.counter + 1;
              this.badges.push('Organized');
            }
          }

          break;

        case 'Social':
          if (this.badges.includes('Social')) {
            this.badges.slice(this.badges.indexOf('Social'), 1);
            this.counter = this.counter - 1;
          } else {
            if (this.counter === 3) {
              window.alert('You can only award three badges!');
            } else {
              this.counter = this.counter + 1;
              this.badges.push('Social');
            }
          }

          break;

        default:
          break;
      }
    } else {
      window.alert('You can only award three badges!');
    }
  }

  honor() {
    let map = this.badges.reduce(function (obj, b) {
      obj[b] = ++obj[b] || 1;
      return obj;
    }, {});
    if (map.Ambiance) {
      this.profileBadges.Ambiance = this.profileBadges.Ambiance + 1;
    }

    if (map.Drinks) {
      this.profileBadges.Drinks = this.profileBadges.Drinks + 1;
    }

    if (map.Entertaining) {
      this.profileBadges.Entertaining = this.profileBadges.Entertaining + 1;
    }

    if (map.Flirty) {
      this.profileBadges.Flirty = this.profileBadges.Flirty + 1;
    }

    if (map.Food) {
      this.profileBadges.Food = this.profileBadges.Food + 1;
    }

    if (map.Music) {
      this.profileBadges.Music = this.profileBadges.Music + 1;
    }

    if (map.Organized) {
      this.profileBadges.Organized = this.profileBadges.Organized + 1;
    }

    if (map.Social) {
      this.profileBadges.Social = this.profileBadges.Social + 1;
    }

    this.userService.updateBadges(this.profileBadges, this.hostID);
    this.partyService.updateHonored(this.internal_id);
    this.partyLevel = this.partyLevel + 1;
    this.userService.updatePartyLevel(this.partyLevel, this.reviewerID);
    this.closeModal();
  }

  toggleClass(event: any, testclass: string, testclass2: string) {
    const hasClass = event.target.classList.contains(testclass);
    const hasClass2 = event.target.classList.contains(testclass2);

    if (this.counter < 3) {
      if (hasClass && !hasClass2) {
        this.renderer.removeClass(event.target, testclass);
        this.renderer.addClass(event.target, testclass2);
      } else {
        this.renderer.removeClass(event.target, testclass2);
        this.renderer.addClass(event.target, testclass);
      }
    }
  }

  async closeModal() {
    await this.modalController.dismiss();
  }
}
