import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dateme-modal',
  templateUrl: './dateme-modal.page.html',
  styleUrls: ['./dateme-modal.page.scss'],
})
export class DatemeModalPage implements OnInit {
  constructor(
    private modalController: ModalController,
    private router: Router
  ) {}

  @Input() id: string;
  @Input() name: string;
  @Input() image: string;
  @Input() internal_id: string;
  @Input() chatName: string;

  ngOnInit() {}

  async closeModal() {
    await this.modalController.dismiss();
  }

  goToChat() {
    this.closeModal();
    this.router.navigate(['/tabs/tab4/chats/' + this.chatName]);
  }

  goToProfile() {
    this.closeModal();
    this.router.navigate(['/tabs/tab1/profile/' + this.id]);
  }
}
