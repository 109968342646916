import { Component, OnInit, Input } from '@angular/core';
import { EmailComposer } from '@ionic-native/email-composer/ngx';
import { Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-party-popover',
  templateUrl: './party-popover.component.html',
  styleUrls: ['./party-popover.component.scss'],
})
export class PartyPopoverComponent implements OnInit {
  @Input() party;
  @Input() user;

  constructor(private emailComposer: EmailComposer, private router: Router, private popoverController: PopoverController, private userService: UserService) { }

  ngOnInit() {

  }

  report() {
    if(this.user) {
      let reportEmail = {
        to: 'report@yourgatsby.com',
        subject: 'User report of Party: ' + this.party.name,
        body: 'Sender: ' + this.user.name + " / " + this.user.email + "  " + 'Party: ' + this.party.name + "  " + 'Reason for report (optional): ',
        isHtml: true
      };
      this.emailComposer.open(reportEmail);
    } else {
      this.closePopover();
      this.router.navigate(['/login']);
    }
  }

  hideParty() {
    if(this.user) {
    this.userService.updateHiddenParties(this.party.internal_id, this.user.internal_id)
    this.closePopover();
    } else {
      this.closePopover();
      this.router.navigate(['/login']);
    }
  }

  async closePopover() {
    await this.popoverController.dismiss();
      }
}
