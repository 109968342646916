export class Party {
    internal_id: string;
    ageMax: number;
    ageMin: number;
    canceled: boolean;
    compensationAmount: number;
    description: string;
    dressCode: string;
    endOfParty: string;
    guestsNeedHostConfirmation: boolean;
    hostName: string;
    host_id: string;
    hostImage: string;
    image: string;
    music: string;
    name: string;
    maxGuests: string;
    currentGuests: number;
    percentFemaleGender: number;
    startOfParty: string;
    theme: string;
    whoBringsWhat: string;
    street: string;
    zip: string;
    city: string;
    state: string;
    country: string;
    createdAt: any;
    geopoint: any;
}