import { Injectable } from '@angular/core';
import { User } from '../shared/user';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  token;

  constructor(
    private afs: AngularFirestore,
    private Firebase: FirebaseX,
    private platform: Platform
  ) {}

  getUser(userId: string): Observable<any> {
    return this.afs
      .doc<User>('user/' + userId)
      .snapshotChanges()
      .pipe(
        map((action) => {
          const data = action.payload.data() as User;
          const _id = action.payload.id;
          return { _id, ...data };
        })
      );
  }

  /*getUser2(userId: string): Observable<any> {
     return this.afs
    .doc<User>("user/" + userId)
    .snapshotChanges()
    .pipe(
      map(action => {
      return action.payload.exists;

      })
    );
  }
  */

  createUser(user: any): Promise<any> {
    if (this.platform.is('cordova')) {
      this.Firebase.getToken().then((token) => {
        this.token = token;
        return this.afs
          .collection('user')
          .doc(user.internal_id)
          .set({
            internal_id: user.internal_id,
            blockPushMessages: user.blockPushMessages,
            email: user.email,
            isAnonymous: user.isAnonymous,
            name: user.name,
            partyLevel: user.partyLevel,
            username: user.username,
            dateOfBirth: user.dateOfBirth,
            gender: user.gender,
            homeTown: user.homeTown,
            profilePicture: user.profilePicture,
            registrationDate: user.registrationDate,
            relationshipStatus: user.relationshipStatus,
            school: user.school,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            pushToken: this.token,
            reviews: [],
            badges: {
              Ambiance: 0,
              Drinks: 0,
              Entertaining: 0,
              Flirty: 0,
              Food: 0,
              Music: 0,
              Organized: 0,
              Social: 0,
            },
            hiddenParties: [],
            blockedUsers: [],
          });
      });
    } else {
      return this.afs
        .collection('user')
        .doc(user.internal_id)
        .set({
          internal_id: user.internal_id,
          blockPushMessages: user.blockPushMessages,
          email: user.email,
          isAnonymous: user.isAnonymous,
          name: user.name,
          partyLevel: user.partyLevel,
          username: user.username,
          dateOfBirth: user.dateOfBirth,
          gender: user.gender,
          homeTown: user.homeTown,
          profilePicture: user.profilePicture,
          registrationDate: user.registrationDate,
          relationshipStatus: user.relationshipStatus,
          school: user.school,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          pushToken: '',
          reviews: [],
          badges: {
            Ambiance: 0,
            Drinks: 0,
            Entertaining: 0,
            Flirty: 0,
            Food: 0,
            Music: 0,
            Organized: 0,
            Social: 0,
          },
          hiddenParties: [],
          blockedUsers: [],
        });
    }

    return;
  }

  userExists(userId: any): boolean {
    let exists;
    this.afs.firestore
      .doc('/user/' + userId)
      .get()
      .then((docSnapshot) => {
        if (docSnapshot.exists) {
          exists = true;
        }
      });
    return exists;
  }

  updateUser(data, id): Promise<any> {
    return this.afs.collection('user').doc(id).update({
      pushToken: data,
    });
  }

  updatePartyLevel(partylevel, id): Promise<any> {
    return this.afs.collection('user').doc(id).update({
      partyLevel: partylevel,
    });
  }

  updateBirthDate(data, id) {
    const db = firebase.firestore();
    const storyRef = db.collection('user').doc(id);
    storyRef.update({
      dateOfBirth: data,
    });
  }

  updateRelationShipStatus(data, id) {
    const db = firebase.firestore();
    const storyRef = db.collection('user').doc(id);
    storyRef.update({
      relationshipStatus: data,
    });
  }

  updateGender(data, id) {
    const db = firebase.firestore();
    const storyRef = db.collection('user').doc(id);
    storyRef.update({
      gender: data,
    });
  }

  updateReviews(data, id) {
    const db = firebase.firestore();
    const storyRef = db.collection('user').doc(id);
    storyRef.update({
      reviews: firebase.firestore.FieldValue.arrayUnion.apply(null, data),
    });
  }

  updateBadges(data, id) {
    const db = firebase.firestore();
    const storyRef = db.collection('user').doc(id);
    storyRef.update({ badges: data });
  }

  updateHiddenParties(data, id) {
    const db = firebase.firestore();
    const storyRef = db.collection('user').doc(id);
    storyRef.update({
      hiddenParties: firebase.firestore.FieldValue.arrayUnion(data),
    });
  }

  updateBlockedUsers(data, id) {
    const db = firebase.firestore();
    const storyRef = db.collection('user').doc(id);
    storyRef.update({
      blockedUsers: firebase.firestore.FieldValue.arrayUnion(data),
    });
  }

  updateBlockedUsersAfterDeleting(data, id) {
    const db = firebase.firestore();
    const storyRef = db.collection('user').doc(id);
    storyRef.update({
      blockedUsers: firebase.firestore.FieldValue.arrayUnion.apply(null, data),
    });
  }

  updateBlockedUsersWhenNotExisting(data, id) {
    const db = firebase.firestore();
    const storyRef = db.collection('user').doc(id);
    storyRef.update({
      blockedUsers: data,
    });
  }

  deleteBlockedUsers(id): Promise<any> {
    return this.afs.collection('user').doc(id).update({
      blockedUsers: firebase.firestore.FieldValue.delete(),
    });
  }

  updateMessageNotifications(data, id) {
    const db = firebase.firestore();
    const storyRef = db.collection('user').doc(id);
    storyRef.update({
      messageNotifications: data,
    });
  }

  updateNotificationsCount(data, id) {
    const db = firebase.firestore();
    const storyRef = db.collection('user').doc(id);
    storyRef.update({
      notificationsCount: data,
    });
  }
}
