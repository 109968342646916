export class User {
    internal_id: string;
    blockPushMessages: boolean;
    email: string;
    isAnonymous: boolean;
    name: string;
    partyLevel: number;
    username: string;
    dateOfBirth: string;
    gender: string;
    homeTown: string;
    profilePicture: string;
    registrationDate: string;
    relationshipStatus: string;
    school: string;
    pushToken: any;
    reviews: Array<any>;
    badges: Array<string>;
    hiddenParties: Array<string>;
    blockedUsers: Array<string>;
    messageNotifications: number;
    notificationsCount: number;
}
