import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'tab1',
    loadChildren: () => import('./tab1/tab1.module').then( m => m.Tab1PageModule)
  },
  {
    path: 'tab2',
    loadChildren: () => import('./tab2/tab2.module').then( m => m.Tab2PageModule)
  },
  {
    path: 'tab3',
    loadChildren: () => import('./tab3/tab3.module').then( m => m.Tab3PageModule)
  },
  {
    path: 'tab4',
    loadChildren: () => import('./tab4/tab4.module').then( m => m.Tab4PageModule)
  },
  {
    path: 'tab5',
    loadChildren: () => import('./tab5/tab5.module').then( m => m.Tab5PageModule)
  },
  {
    path: 'find-party',
    loadChildren: () => import('./find-party/find-party.module').then( m => m.FindPartyPageModule)
  },
  {
    path: 'party-detail',
    loadChildren: () => import('./party-detail/party-detail.module').then( m => m.PartyDetailPageModule)
  },
  {
    path: 'host-party',
    loadChildren: () => import('./host-party/host-party.module').then( m => m.HostPartyPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./signup/signup.module').then( m => m.SignupPageModule)
  },
  {
    path: 'chat',
    loadChildren: () => import('./chat/chat.module').then( m => m.ChatPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'request-modal',
    loadChildren: () => import('./request-modal/request-modal.module').then( m => m.RequestModalPageModule)
  },
  {
    path: 'guest-list',
    loadChildren: () => import('./guest-list/guest-list.module').then( m => m.GuestListPageModule)
  },
  {
    path: 'review-modal',
    loadChildren: () => import('./review-modal/review-modal.module').then( m => m.ReviewModalPageModule)
  },
  {
    path: 'dateme-modal',
    loadChildren: () => import('./dateme-modal/dateme-modal.module').then( m => m.DatemeModalPageModule)
  },
  {
    path: 'confirm',
    loadChildren: () => import('./confirm/confirm.module').then( m => m.ConfirmPageModule)
  },  {
    path: 'honor-modal',
    loadChildren: () => import('./honor-modal/honor-modal.module').then( m => m.HonorModalPageModule)
  },
  {
    path: 'show-review-modal',
    loadChildren: () => import('./show-review-modal/show-review-modal.module').then( m => m.ShowReviewModalPageModule)
  }

];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
