import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ShowReviewModalPageRoutingModule } from './show-review-modal-routing.module';

import { ShowReviewModalPage } from './show-review-modal.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ShowReviewModalPageRoutingModule
  ],
  declarations: [ShowReviewModalPage]
})
export class ShowReviewModalPageModule {}
