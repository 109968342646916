import { Component, OnInit, Input } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { PartyService } from '../services/party.service';
import { User } from '../shared/user';
import { Party } from '../shared/party';
import { AuthorizationKey } from '../shared/firebase_fcm_AuthorizationKey';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-request-modal',
  templateUrl: './request-modal.page.html',
  styleUrls: ['./request-modal.page.scss']
})
export class RequestModalPage implements OnInit {
  modalTitle: string;
  modelId: number;
  onClosedData = '';
  dataReturned: any;

  constructor(
    private modalController: ModalController,
    private navParams: NavParams,
    private http: HttpClient,
    private partyservice: PartyService,
    private userService: UserService
  ) {}

  @Input() host: User;
  @Input() request: any;
  @Input() party: Party;

  
  ngOnInit() {
    console.table(this.navParams);
    this.modelId = this.navParams.data.paramID;
    this.modalTitle = this.navParams.data.paramTitle;
  }
  async closeModal() {
    await this.modalController.dismiss(this.onClosedData);
  }

  partyRequest() {
    this.request.message = this.onClosedData;
    this.partyservice.partyRequest(this.request, this.party.internal_id);
    this.host.notificationsCount = this.host.notificationsCount + 1;
    this.userService.updateNotificationsCount(this.host.notificationsCount, this.host.internal_id);
    this.http
      .post(
        'https://fcm.googleapis.com/fcm/send',
        {
          to: this.host.pushToken,
          notification: {
            body: this.request.message,
            title: 'Party request for ' + this.party.name
          }
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'key=' + AuthorizationKey
          }
        }
      )
      .subscribe(
        (val: any) => {
          
        },
        response => {
        
        },
        () => {
          this.closeModal();
         
        }
      );
  }
}
