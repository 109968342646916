import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { EmailComposer } from '@ionic-native/email-composer/ngx';

@Component({
  selector: 'app-show-review-modal',
  templateUrl: './show-review-modal.page.html',
  styleUrls: ['./show-review-modal.page.scss'],
})
export class ShowReviewModalPage implements OnInit {

  @Input() review: any;
  @Input() name: string;
  @Input() user: any;

  constructor(public modalController: ModalController, private router: Router, private emailComposer: EmailComposer) { }

  ngOnInit() {
  }

  async closeModal() {
    await this.modalController.dismiss();
  }

  goToProfile() {
    this.closeModal();
    this.router.navigate(['/tabs/tab1/profile/' + this.review.reviewerID]);
  }

  goToParty() {
    this.closeModal();
    this.router.navigate(['/tabs/tab1/party-detail/' + this.review.partyID]);
  }

  report() {
    if(this.user) {
      let reportEmail = {
        to: 'report@yourgatsby.com',
        subject: 'User report of Review on ' + this.name + "'s profile",
        body: 'Sender: ' + this.user.name + " / " + this.user.email + "  " + 'Reason for report (optional): ',
        isHtml: true
      };
      this.emailComposer.open(reportEmail);
      this.closeModal();
    } else {
      this.closeModal();
      this.router.navigate(['/login']);
    }
  }
}
