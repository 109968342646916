import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Party } from '../shared/party';
import { AuthService } from '../services/auth.service';
import * as firebase from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class PartyService {
  private currentUser: firebase.User = null;
  private userId: string = undefined;

  constructor(private afs: AngularFirestore, private authService: AuthService) {
    this.authService.getAuthState().subscribe(user => {
      if (user) {
        // User is signed in.
        this.currentUser = user;
        this.userId = user.uid;
      } else {
        this.currentUser = null;
        this.userId = undefined;
      }
    });
  }

  getParties(): Observable<Party[]> {
    return this.afs
      .collection<Party>('parties')
      .snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(action => {
            const data = action.payload.doc.data() as Party;
            const _id = action.payload.doc.id;
            return { _id, ...data };
          });
        })
      );
  }

  getParty(id: string): Observable<Party> {
    return this.afs
      .doc<Party>('parties/' + id)
      .snapshotChanges()
      .pipe(
        map(action => {
          const data = action.payload.data() as Party;
          const _id = action.payload.id;
          return { _id, ...data };
        })
      );
  }

  getPartiesByUser(userID: string): Observable<any[]> {
    return this.afs
      .collection('parties', ref =>
        ref.where('host_id', '==', userID).orderBy('createdAt', 'desc')
      )
      .valueChanges();
  }

  getFeaturedParty(): Observable<Party> {
    return this.afs
      .collection<Party>('parties', ref => ref.where('featured', '==', true))
      .snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(action => {
            const data = action.payload.doc.data() as Party;
            const _id = action.payload.doc.id;
            return { _id, ...data };
          })[0];
        })
      );
  }

  getPartyIds(): Observable<String[] | any> {
    return this.getParties()
      .pipe(map(parties => parties.map(party => party.internal_id)))
      .pipe(catchError(error => error));
  }

  postParty(party: any): Promise<any> {
    let generatedID =
      party.host_id +
      'Version' +
      Math.floor(Math.random() * 10000000000).toString();
    if (this.currentUser) {
      return this.afs
        .collection('parties')
        .doc(generatedID)
        .set({
          internal_id: generatedID,
          ageMax: party.ageMax,
          ageMin: party.ageMin,
          canceled: false,
          compensationAmount: 0,
          description: party.description,
          dressCode: party.dressCode,
          endOfParty: party.endOfParty,
          guestsNeedHostConfirmation: party.guestsNeedHostConfirmation,
          hostName: party.hostName,
          host_id: party.host_id,
          hostImage: party.hostImage,
          image: party.image,
          music: party.music,
          name: party.name,
          maxGuests: party.maxGuests,
          currentGuests: 1,
          percentFemaleGender: 50,
          startOfParty: party.startOfParty,
          theme: party.theme,
          whoBringsWhat: party.whoBringsWhat,
          street: party.street,
          zip: party.zip,
          city: party.city,
          state: party.state,
          country: party.country,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          geopoint: party.geopoint
        });
    } else {
      return Promise.reject(new Error('No User Logged In!'));
    }
  }

  updatePartyGuests(data, id) {
    const db = firebase.firestore();
    const storyRef = db.collection('parties').doc(id);
    storyRef.update({
      currentGuests: data
    });
  }

  partyRequest(request: any, party_id: any): Promise<any> {
    let generatedID =
    party_id +
      '_V_' +
      Math.floor(Math.random() * 10000000000).toString();
    if (this.currentUser) {
      return this.afs
        .collection('party_request')
        .doc(generatedID)
        .set({
          internal_id: generatedID,
          guest_id: request.guest_id,
          host_id: request.host_id,
          party_id: request.party_id,
          party_name: request.party_name,
          status: request.status,
          message: request.message,
          guestName: request.guestName,
          guestImage: request.guestImage,
          endOfParty: request.endOfParty,
          hostImage: request.hostImage,
          hostName: request.hostName,
          reviewed: false,
          honored: false,
          createdAt: firebase.firestore.FieldValue.serverTimestamp()
        });
    } else {
      return Promise.reject(new Error('No User Logged In!'));
    }
  }

  getNotAcceptedRequestsByHost(id: string): Observable<any[]> {
    return this.afs
      .collection('party_request', ref =>
        ref
          .where('host_id', '==', id)
          .where('status', '==', 'not accepted')
          .orderBy('createdAt', 'desc')
      )
      .valueChanges();
  }

  getAcceptedAndNotReviewedRequestsByHost(id: string): Observable<any[]> {
    return this.afs
      .collection('party_request', ref =>
        ref
          .where('host_id', '==', id)
          .where('status', '==', 'accepted')
          .where('reviewed', '==', false)
      )
      .valueChanges();
  }

  getRequestByUser(id: string): Observable<any> {
    return this.afs
      .collection('party_request', ref => ref.where('guest_id', '==', id))
      .valueChanges();
  }

  getAcceptedAndNotHonoredRequestByUser(id: string): Observable<any> {
    return this.afs
      .collection('party_request', ref => ref.where('guest_id', '==', id).where('status', '==', 'accepted').where('honored', '==', false))
      .valueChanges();
  }

  getRequestByUserAndParty(userId: string, partyId: string): Observable<any> {
    return this.afs
      .collection('party_request', ref =>
        ref.where('party_id', '==', partyId).where('guest_id', '==', userId)
      )
      .valueChanges();
  }

  getAcceptedRequestsByParty(partyId: string): Observable<any[]> {
    return this.afs
      .collection('party_request', ref =>
        ref.where('party_id', '==', partyId).where('status', '==', 'accepted')
      )
      .valueChanges();
  }

  getGuestList(partyId: string): Observable<any[]> {
    return this.afs
      .collection('party_request', ref =>
        ref.where('party_id', '==', partyId).where('status', '==', 'accepted')
      )
      .valueChanges();
  }

  acceptRequest(id: any) {
    const db = firebase.firestore();
    const storyRef = db.collection('party_request').doc(id);
    storyRef.update({
      status: 'accepted'
    });
  }

  declineRequest(id) {
    this.afs
      .collection('party_request')
      .doc(id)
      .delete();
  }

  uploadToFirebase(_imageBlobInfo) {
   
    return new Promise((resolve, reject) => {
      let fileRef = firebase.storage().ref('images/' + _imageBlobInfo.fileName);

      let uploadTask = fileRef.put(_imageBlobInfo.imgBlob);

      uploadTask.on(
        'state_changed',
        (_snap: any) => {
          console.log(
            'progess ' + (_snap.bytesTransferred / _snap.totalBytes) * 100
          );
        },
        _error => {
      
          reject(_error);
        },
        () => {
          // completion...
          resolve(uploadTask.snapshot);
        }
      );
    });
  }

  getFutureParties(): Observable<any>{
    return this.afs
    .collection('parties', ref =>
      ref.where('endOfParty', '>=', this.formatDate(new Date()))
    )
    .valueChanges();
  }

  formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}

getPastPartiesByUser(userID: string): Observable<any[]> {
  return this.afs
    .collection('parties', ref =>
      ref.where('host_id', '==', userID).where('startOfParty', '<=', this.formatDate(new Date())).orderBy('createdAt', 'desc')
    )
    .valueChanges();
}

updateReviewed(id) {
  const db = firebase.firestore();
  const storyRef = db.collection('party_request').doc(id);
  storyRef.update({ reviewed: true });
}

updateHonored(id) {
  const db = firebase.firestore();
  const storyRef = db.collection('party_request').doc(id);
  storyRef.update({ honored: true });
}

postDateMeObject(objectName, id, userName, guestID, guestName, userImage1, userImage2, reversedInternal_id, partyID) {
  const temp = objectName.toLowerCase().replace(/[\. ,:-]+/g, '');
  const createid = this.replaceUmlaute(temp);
  
  const temp2 = reversedInternal_id.toLowerCase().replace(/[\. ,:-]+/g, '');
  const createid2 = this.replaceUmlaute(temp2);
  if (this.currentUser) {
    return this.afs
      .collection('date_me')
      .doc(createid)
      .set({
        internal_id: createid,
        userID1: id,
        userName1: userName,
        userImage1: userImage1,
        userID2: guestID,
        userName2: guestName,
        userImage2: userImage2,
        matched: false,
        reversedInternal_id: createid2,
        partyID: partyID,
        createdAt: firebase.firestore.FieldValue.serverTimestamp()
      });
  } else {
    return Promise.reject(new Error('No User Logged In!'));
  }
}

updateDateMeObject(id) {
  const db = firebase.firestore();
  const storyRef = db.collection('date_me').doc(id);
  storyRef.update({ matched: true });
}

updateDateMeObjectNameAndID(id, data) {
  const db = firebase.firestore();
  const storyRef = db.collection('date_me').doc(id);
  storyRef.update({ userID2: data.id, userName2: data.name });
}

getAllDateMeObjects(): Observable<Party[]> {
  return this.afs
    .collection<Party>('date_me')
    .snapshotChanges()
    .pipe(
      map(actions => {
        return actions.map(action => {
          const data = action.payload.doc.data() as Party;
          const _id = action.payload.doc.id;
          return { _id, ...data };
        });
      })
    );
}

getDateMeObjectsByUser(userID: string): Observable<any[]> {
  return this.afs
    .collection('date_me', ref =>
      ref.where('userID1', '==', userID)
    )
    .valueChanges();
}

getDateMeObjectsByParty(partyID: string): Observable<any[]> {
  return this.afs
    .collection('date_me', ref =>
      ref.where('partyID', '==', partyID)
    )
    .valueChanges();
}

getDateMeObjectsByMatched(userID: string): Observable<any[]> {
  return this.afs
    .collection('date_me', ref =>
      ref.where('userID1', '==', userID).where('matched', '==', true)
    )
    .valueChanges();
}

getDateMeObjectsByMatched2(userID: string): Observable<any[]> {
  return this.afs
    .collection('date_me', ref =>
      ref.where('userID2', '==', userID).where('matched', '==', true)
    )
    .valueChanges();
}

getDateMeObjectsOfTwoUsersByParty(userID: string, userID2: string, partyID: string): Observable<any[]> {
  return this.afs
    .collection('date_me', ref =>
      ref.where('userID1', '==', userID).where('userID2', '==', userID2).where('partyID', '==', partyID)
    )
    .valueChanges();
}

hideDateMeObject(id) {
  const db = firebase.firestore();
  const storyRef = db.collection('date_me').doc(id);
  storyRef.update({ hidden: true });
}

deleteParty(id): Promise<any> {
  return this.afs.collection('parties').doc(id).delete();
}

replaceUmlaute(str) {
  const umlautMap = {
    '\u00dc': 'UE',
    '\u00c4': 'AE',
    '\u00d6': 'OE',
    '\u00fc': 'ue',
    '\u00e4': 'ae',
    '\u00f6': 'oe',
    '\u00df': 'ss',
  }
  return str
    .replace(/[\u00dc|\u00c4|\u00d6][a-z]/g, (a) => {
      const big = umlautMap[a.slice(0, 1)];
      return big.charAt(0) + big.charAt(1).toLowerCase() + a.slice(1);
    })
    .replace(new RegExp('['+Object.keys(umlautMap).join('|')+']',"g"),
      (a) => umlautMap[a]
    );
}
}

