import { Component, OnInit, Input } from '@angular/core';
import { EmailComposer } from '@ionic-native/email-composer/ngx';
import { Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-profile-popover',
  templateUrl: './profile-popover.component.html',
  styleUrls: ['./profile-popover.component.scss'],
})
export class ProfilePopoverComponent implements OnInit {

  @Input() profile;
  @Input() user;
  
  constructor(private emailComposer: EmailComposer, private router: Router, private popoverController: PopoverController, private userService: UserService) { }

  ngOnInit() {

  }

  report() {
    if(this.user) {
      let reportEmail = {
        to: 'report@yourgatsby.com',
        subject: 'User report of User: ' + this.profile.name,
        body: 'Sender: ' + this.user.name + " / " + this.user.email + "  " + 'User: ' + this.profile.name + "  " + 'Reason for report (optional): ',
        isHtml: true
      };
      this.emailComposer.open(reportEmail);
    } else {
      this.closePopover();
      this.router.navigate(['/login']);
    }
  }

  block() {
    if(this.user) {
      this.userService.updateBlockedUsers(this.profile.internal_id, this.user.internal_id)
      this.closePopover();
      } else {
        this.closePopover();
        this.router.navigate(['/login']);
      }
  }

  unblock() {
    if(this.user) {
      this.user.blockedUsers.splice(this.user.blockedUsers.indexOf(this.profile.internal_id), 1);
      if(this.user.blockedUsers.length === 0) {
        this.userService.deleteBlockedUsers(this.user.internal_id).then(resolve => {
          this.user.blockedUsers = [];
      
          this.userService.updateBlockedUsersWhenNotExisting(this.user.blockedUsers, this.user.internal_id)
          this.closePopover();
        });
       
      } else {
        this.userService.deleteBlockedUsers(this.user.internal_id).then(resolve => {
          this.userService.updateBlockedUsersAfterDeleting(this.user.blockedUsers, this.user.internal_id)
          this.closePopover();
        });
        
      }
    
      } else {
        this.closePopover();
        this.router.navigate(['/login']);
      }
  }

  async closePopover() {
    await this.popoverController.dismiss();
      }

      userNotBlocked() {
        if (this.user) {
          if (this.user.blockedUsers.includes(this.profile.internal_id)) {
            return false;
          } else {
            return true;
          }
        } else {
          return true;
        }
      }
}
