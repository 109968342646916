import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UserService } from '../services/user.service';
import { PartyService } from '../services/party.service';

@Component({
  selector: 'app-review-modal',
  templateUrl: './review-modal.page.html',
  styleUrls: ['./review-modal.page.scss'],
})
export class ReviewModalPage implements OnInit {
  reviewScore: boolean;
  reviewText = '';

  reviews = [];

  constructor(
    private modalController: ModalController,
    private userService: UserService,
    private partyService: PartyService
  ) {}

  @Input() id: string;
  @Input() name: string;
  @Input() image: string;
  @Input() guestName: string;
  @Input() guestImage: string;
  @Input() reviewed: string;
  @Input() internal_id: string;
  @Input() reviewerID: string;
  @Input() partyLevel: number;
  @Input() partyName: string;
  @Input() partyID: string;

  ngOnInit() {
    this.reviewScore = false;
  }

  async closeModal() {
    await this.modalController.dismiss();
  }

  review() {
    this.reviews.push({
      reviewScore: this.reviewScore,
      reviewText: this.reviewText,
      reviewerImage: this.image,
      reviewerName: this.name,
      reviewerID: this.reviewerID,
      partyName: this.partyName,
      partyID: this.partyID
    });
    this.userService.updateReviews(this.reviews, this.id);
    this.partyLevel = this.partyLevel + 1;
    this.userService.updatePartyLevel(this.partyLevel, this.reviewerID);
    this.partyService.updateReviewed(this.internal_id);
    this.closeModal();
  }
}
