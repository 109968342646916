import { Injectable, NgZone } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import {
  AngularFirestore,
  AngularFirestoreDocument
} from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { Facebook } from '@ionic-native/facebook/ngx';
import { Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import { switchMap, first, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user$: Observable<any>;

  private authState: Observable<firebase.User>;
  private currentUser: firebase.User = null;
  valid: boolean;

  GOOGLEPLUS_OPTIONS = {
    webClientId:
      '845060352133-q3mlup97fgeqqp2nqc2n06uq0ds9jhd4.apps.googleusercontent.com',
    offline: true,
    scopes: 'profile email'
  };

  constructor(
    public afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private gplus: GooglePlus,
    private facebook: Facebook,
    private platform: Platform,
    private router: Router,
    private ngZone: NgZone
  ) {
    this.user$ = this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          return this.afs.doc<any>(`user/${user.uid}`).valueChanges();
        } else {
          return of(null);
        }
      })
    );

    this.authState = this.afAuth.authState;
    this.authState.subscribe(user => {
      if (user) {
        this.currentUser = user;
      } else {
        this.currentUser = null;
      }
    });
  }

  getAuthState() {
    return this.authState;
  }

  logIn(user: any): boolean {
    this.afAuth.auth
      .signInWithEmailAndPassword(user.username, user.password)
      .then(result => {
        if (result.user.emailVerified !== true) {
          this.SendVerificationMail().then(resolve => {
            
            this.logOut();
            window.alert(
              'Please validate your email address. Kindly check your inbox.'
            );
          });
        } else {
          this.ngZone.run(() => {
            this.valid = true;
          });
        }
      })
      .catch(error => {
        window.alert(error.message);
      });

    return this.valid;
  }

  logOut() {
    this.afAuth.auth.signOut();
  }

  async googleLogin() {
    if (this.platform.is('cordova')) {
 
      const gplusUser = await this.gplus.login(this.GOOGLEPLUS_OPTIONS);
      let credentials = firebase.auth.GoogleAuthProvider.credential(
        null,
        gplusUser.accessToken
      );
      return await this.afAuth.auth.signInWithCredential(credentials);
    } else {
    
      const _provider = new firebase.auth.GoogleAuthProvider();
      const _userWebConnect = await this.afAuth.auth.signInWithPopup(_provider);
      return _userWebConnect.user;
    }
  }

  SignUp(credentials) {
    return this.afAuth.auth
      .createUserWithEmailAndPassword(credentials.email, credentials.password)
      .then(result => {
        this.SendVerificationMail().then(() => {
          this.router.navigate(['/confirm']);
          this.logOut();
        }); // Sending email verification notification, when new user registers
        
      })
      .catch(error => {
        window.alert(error.message);
      });
  }

  signOut() {
    this.afAuth.auth.signOut();
  }

  /*async nativeGoogleLogin(): Promise<firebase.User> {
    try {
      const gplusUser = await this.gplus.login({
        webClientId:
          "845060352133-q3mlup97fgeqqp2nqc2n06uq0ds9jhd4.apps.googleusercontent.com",
        offline: true,
        scopes: "profile email"
      });
      return await this.afAuth.auth.signInWithCredential(
        firebase.auth.GoogleAuthProvider.credential(gplusUser.idToken)
      );
    } catch (err) {
      console.log(err);
    }

  }
  async webGoogleLogin(): Promise<void> {
    try {
      const provider = new firebase.auth.GoogleAuthProvider();
      const credential = await this.afAuth.auth.signInWithPopup(provider);
    } catch (err) {
      console.log(err);
    }
  }
  */

  facebookLogin(): Promise<any> {
    return this.facebook
      .login(['email'])
      .then(response => {
        const facebookCredential = firebase.auth.FacebookAuthProvider.credential(
          response.authResponse.accessToken
        );

        firebase
          .auth()
          .signInWithCredential(facebookCredential)
          .then(success => {
       
          });
      })
      .catch(error => {
     
      });
  }

  SendVerificationMail() {
    return this.afAuth.auth.currentUser.sendEmailVerification();
  }
}
